import { createContext, useEffect, useState } from 'react';
import { Map, View } from 'ol';
import { MapOptions } from 'ol/PluggableMap';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

interface MapContextProps {
  map: Map;
}

export const MapContext = createContext<MapContextProps>({
  map: null,
});

export const MapProvider = ({ children }: any) => {
  const [map, setMap] = useState<Map>(null);

  useEffect(() => {
    const options: MapOptions = {
      view: new View({
        projection: 'EPSG:3857',
        center: [0, 0],
        zoom: 2,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      controls: [],
      overlays: [],
    };

    const mapObject = new Map(options);

    setMap(mapObject);
  }, []);

  return <MapContext.Provider value={{ map }}>{children}</MapContext.Provider>;
};
