import { Navigate } from 'react-router-dom';
import { Id, toast, ToastContent, ToastOptions } from 'react-toastify';
import { updateNotification } from '../db/notifications';

export const handleNotifications = (event: MessageEvent<any>) => {
  const { tag, body, userId, notificationId } = event.data;
  let toastType: (
    content: ToastContent,
    options?: ToastOptions<{}>
  ) => React.ReactText;

  let onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;

  switch (tag) {
    case 'leadAccepted':
      toastType = toast.success;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/leads/${body.refId}` });
      };
      break;
    case 'leadAssigned':
      toastType = toast.success;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/leads/${body.refId}` });
      };
      break;
    case 'leadAssignment':
      toastType = toast.info;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/leads/${body.refId}` });
      };
      break;
    case 'leadCreated':
      toastType = toast.info;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/leads/${body.refId}` });
      };
      break;
    case 'orderCreated':
      toastType = toast.info;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/orders/${body.refId}` });
      };
      break;
    case 'orderAccepted':
      toastType = toast.success;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/orders/${body.refId}` });
      };
      break;
    case 'orderFulfillmentFailed':
      toastType = toast.error;
      onClick = (event) => {
        event.preventDefault();
        updateNotification(userId, notificationId, { read: true });
        Navigate({ to: `/orders/${body.refId}` });
      };
      break;
    default:
      toastType = toast.info;
      onClick = (event) => {
        return;
      };
  }

  toastType(body, {
    onClick,
  });
};
