import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Confirm } from '../forms/Confirm';
import Textarea from '../forms/Textarea';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

type AcceptOrderForm = {
  comment: string;
};

type AcceptOrderProps = {
  open: boolean;
  onAccept: SubmitHandler<AcceptOrderForm>;
  setOpen: (boolean) => void;
};

const AcceptOrder = ({ open, onAccept, setOpen }: AcceptOrderProps) => {
  const { register, handleSubmit } = useForm<AcceptOrderForm>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    handleSubmit(onAccept)();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="¿Está seguro de que desea aceptar el pedido?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="acceptOrderForm" className="flex flex-col">
        <label className="font-bold mb-2">Comentario: </label>
        <Textarea
          name="comment"
          register={register}
          options={{ required: false }}
          cols={30}
          rows={5}
          maxLength={142}
          className="mb-2"
        />
      </form>
      <Button
        text="Aceptar"
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default AcceptOrder;
