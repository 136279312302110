// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { firestore } from './lib/utils/firebase';
import packagejson from '../package.json';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    /*     // add event listener of register for offline / online
    window.addEventListener('offline', () => {
      console.log('site offline');
    });

    window.addEventListener('online', () => {
      console.log('site online');
    }); */

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      console.log(`Version: ${packagejson.version}`);

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      if (registration.waiting) {
        const reg = registration.waiting;
        reg.postMessage({ type: 'SKIP_WAITING' });
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              var cachesToKeep = ['none'];
              caches.keys().then(function (keyList) {
                return Promise.all(
                  keyList.map((key) => {
                    if (cachesToKeep.indexOf(key) === -1) {
                      return caches.delete(key);
                    } else return null;
                  })
                );
              });

              window.location.reload();

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function forceSWupdate() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.update();
      }
    });
  }
}

export async function saveLastLogin(uid) {
  setTimeout(async () => {
    const ref = doc(firestore, 'identities', uid);
    try {
      await updateDoc(ref, {
        lastLogin: Timestamp.now(),
        appVersion: packagejson.version,
      });
    } catch (e) {
      console.log(e);
    }
  }, 5000);
}

export async function saveEndpoints(uid, endpoints) {
  //Check if notifications are support
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications!');
    let endpointsNew = endpoints ? endpoints : [];
    endpointsNew.push({
      endpoint: null,
      auth: null,
      p256dh: null,
      deviceType: navigator.userAgent,
      pushNotifications: 'not_supported',
      timestamp: Timestamp.now(),
    });
    updateIdentity(uid, endpointsNew);

    return;
  }
  //If notifications are supported
  else if ('serviceWorker' in navigator) {
    //Check permission
    Notification.requestPermission((status) => {
      console.log('Notification permission status:', status);

      if (status === 'granted') {
        navigator.serviceWorker.ready
          .then(function (serviceWorkerRegistration) {
            const serverKey = urlB64ToUint8Array(
              process.env.REACT_APP_MESSAGING_SENDER_ID
            );
            return serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: serverKey,
            });
          })
          .then(function (subscription) {
            if (endpoints) {
              let checkEndpointOld = false;

              endpoints.forEach(async (endpoint) => {
                if (subscription.toJSON().endpoint === endpoint.endpoint) {
                  checkEndpointOld = true;
                }
              });

              if (!checkEndpointOld) {
                let endpoint = subscription.toJSON();
                let endpointsNew = endpoints;
                endpointsNew.push({
                  endpoint: endpoint.endpoint,
                  auth: endpoint.keys.auth,
                  p256dh: endpoint.keys.p256dh,
                  deviceType: navigator.userAgent,
                  pushNotifications: status,
                  timestamp: Timestamp.now(),
                });

                updateIdentity(uid, endpointsNew);
              }
            } else {
              let endpoint = subscription.toJSON();
              let endpointsNew = [];
              endpointsNew.push({
                endpoint: endpoint.endpoint,
                auth: endpoint.keys.auth,
                p256dh: endpoint.keys.p256dh,
                deviceType: navigator.userAgent,
                pushNotifications: status,
                timestamp: Timestamp.now(),
              });
              updateIdentity(uid, endpointsNew);
            }
          });
      } else {
        console.log('No permissions');
        let endpointsNew = endpoints ? endpoints : [];

        endpointsNew.push({
          endpoint: null,
          auth: null,
          p256dh: null,
          deviceType: navigator.userAgent,
          pushNotifications: status,
          timestamp: Timestamp.now(),
        });

        updateIdentity(uid, endpointsNew);
      }
    });
  }
}

async function updateIdentity(uid, endpoints) {
  setTimeout(async () => {
    const ref = doc(firestore, 'identities', uid);
    try {
      await updateDoc(ref, {
        endpoints: endpoints,
      });
    } catch (e) {
      console.log(e);
    }
  }, 5000);
}

function urlB64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
