import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Order,
  OrderStatus,
} from '../../../../global';
import {
  acceptOrder,
  cancelOrder,
  updateOrder,
} from '../db/orders';
import { firestore, docToJSON } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type OrdersHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  orders: Order[];
};

type OrdersHookOptions = {
  status: OrderStatus;
  pageSize?: number;
  direction?: 'asc' | 'desc';
  fsaId?: string;
};

export const useOrders = ({
  status,
  pageSize = 10,
  direction = 'asc',
}: OrdersHookOptions): OrdersHook => {
  const { id } = useAuth();
  const [ordersQuery, setOrdersQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    ordersQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('status', 'in', status),
          orderBy('createdAt', direction)
        )
      );
    } else if (ordersQuery) {
      setOrdersQuery(undefined);
    }
  }, [id, status, direction]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      orders: values
        ? values.map((v) => docToJSON(v) as Order)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

interface UseOrderHook {
  accept: (comment: string) => Promise<void>;
  error: FirestoreError;
  loading: boolean;
  order: Order;
  cancel: (comment: string) => Promise<void>;
  update: (data: any) => Promise<void>;
}

export const useOrder = (id?: string): UseOrderHook => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'orders', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setOrder(docToJSON(doc) as Order);
          } else {
            setOrder(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const accept = async (comment: string) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await acceptOrder(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const update = async (data: any) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await updateOrder(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const cancel = async (comment: string) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await cancelOrder(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    accept,
    error,
    loading,
    order,
    cancel,
    update,
  };
};
