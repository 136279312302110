import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { docToJSON, firestore } from '../utils/firebase';
import { useAuth } from './use-auth';
import { usePagination } from '../utils/usePagination';
import { FirebaseError } from 'firebase/app';
import { Associate, ChatbotUser, User } from '../../../../global';

export type ChatbotUsersHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  chatbotUsers: ChatbotUser[];
};

export type ChatbotUsersOptions = {
  pageSize?: number;
};

export const useChatbotUsers = ({
  pageSize = 10,
}: ChatbotUsersOptions): ChatbotUsersHook => {
  const { id } = useAuth();
  const [associatesQuery, setAssociatesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    associatesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let newQuery = query(
        collection(firestore, `users`),
        where('roles', 'array-contains', 'chatbotUser')
      );

      setAssociatesQuery(newQuery);
    } else if (associatesQuery) {
      setAssociatesQuery(undefined);
    }
  }, [id, pageSize]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      chatbotUsers: values
        ? values.map((v) => docToJSON(v) as Associate)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useChatbotUser = (id?: string) => {
  const { id: userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [chatbotUser, setChatbotUser] = useState<ChatbotUser | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (userId && id) {
      setLoading(true);
      const ref = doc(firestore, `users/${userId}/associates`, id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists) {
            setChatbotUser(docToJSON(doc) as User);
          } else {
            setChatbotUser(null);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
          setChatbotUser(undefined);
        }
      );
    }
    return unsubscribe;
  }, [id, userId]);

  return {
    chatbotUser,
    error,
    loading,
  };
};
