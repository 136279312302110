import {
  doc,
  updateDoc,
  addDoc,
  collection,
  deleteField,
} from 'firebase/firestore';
import {
  CreateOutletData,
  CreateRetailerData,
  UpdateRetailerData,
  UserChanges,
} from '../../../../global';
import { firestore } from '../utils/firebase';
import { createOutlet } from './outlets';

const updateRetailer = async (
  id: string,
  data: UpdateRetailerData
): Promise<void> => {
  const ref = doc(firestore, 'users', id);
  await updateDoc(ref, data);
};

export const confirmRetailerRegistration = async (id: string) => {
  await updateRetailer(id, { status: 'confirmed' });
};

export const rejectRetailerRegistration = async (
  id: string,
  comment: string
) => {
  await updateRetailer(id, { status: 'rejected', rejectionComment: comment });
};

export const confirmRetailerRejection = async (id: string) => {
  await updateRetailer(id, { status: 'rejectionConfirmed' });
};

export const registerRetailerChanges = async (
  id: string,
  force: boolean,
  changes: UserChanges
) => {
  if (force) {
    await updateRetailer(id, {
      status: 'confirmed',
      ...changes,
      changes: deleteField(),
    });
  } else {
    const updateObj = {};
    Object.keys(changes).forEach((key) => {
      updateObj[`changes.${key}`] = changes[key];
    });
    await updateRetailer(id, {
      status: 'changes',
      ...updateObj,
    });
  }
};

export const confirmRetailerChanges = async (
  id: string,
  changes: UserChanges
) => {
  await updateRetailer(id, {
    status: 'confirmed',
    ...changes,
    changes: deleteField(),
  });
};

export const confirmRetailerChangesRejection = async (id: string) => {
  await updateRetailer(id, {
    status: 'confirmed',
  });
};

export const rejectRetailerChanges = async (
  id: string,
  changes: UserChanges,
  comment?: string
) => {
  if ('outletChanged' in changes) {
    await updateRetailer(id, {
      status: 'changesRejected',
      changes: deleteField(),
      rejectionComment: comment,
    });
  } else {
    await updateRetailer(id, {
      status: 'changesRejected',
      changes: deleteField(),
      rejectionComment: comment,
    });
  }
};

export const createRetailer = async (
  force: boolean,
  data: CreateRetailerData
): Promise<void> => {
  const retailerData = {
    name: data.name,
    phone: data.phone,
    status: force ? 'confirmed' : 'unconfirmed',
    outletChanged: false,
    roles: ['retailer'],
  };

  const retailerRef = await addDoc(
    collection(firestore, 'users'),
    retailerData
  );

  const outletData: CreateOutletData = {
    cluster: data.cluster,
    channel: data.channel,
    location: data.location,
    name: data.businessName,
    primaryDistributor: data.primaryDistributor,
    primaryFSA: data.primaryFSA,
    retailer: {
      id: retailerRef.id,
      name: data.name,
      phone: data.phone,
    },
    status: force ? 'confirmed' : 'unconfirmed',
    userIds: [
      retailerRef.id,
      data.primaryDistributor.id,
      data.primaryFSA.id,
    ],
  };
  await createOutlet(outletData);
};
