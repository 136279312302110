import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useOrder } from '../../../lib/hooks/use-orders';
import AcceptOrder from '../../../components/orders/AcceptOrder';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Edit from '../../../components/ui/Edit';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Content from '../../../components/layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { useAuth } from '../../../lib/hooks/use-auth';
import CancelOrder from '../../../components/orders/CancelOrder';

type OrderDetailsParams = 'id';

const Order = () => {
  const { id } = useParams<OrderDetailsParams>();
  const { id: userId } = useAuth();
  const checkAccess = useCheckAccess();
  const navigate = useNavigate();
  const { accept, error, loading, order, cancel } = useOrder(id);
  const [showAccept, setShowAccept] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [displayAcceptButton, setDisplayAcceptButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (order) {
      switch (order.status) {
        case 'pending':
          setDisplayAcceptButton(true);
          break;
        default:
          break;
      }
    }
  }, [order, userId]);

  const handleAccept = async (data) => {
    await accept(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleCancel = async (data) => {
    await cancel(data.comment);
    navigate('/orders', { replace: true });
  };

  return (
    <>
      {checkAccess(['sop', 'distributor']) && (
        <AcceptOrder
          open={showAccept}
          onAccept={handleAccept}
          setOpen={(open) => {
            setShowAccept(open);
          }}
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <CancelOrder
          open={showCancel}
          onCancel={handleCancel}
          setOpen={(open) => setShowCancel(open)}
        />
      )}

      <Loader show={loading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Content>
        {loading || order ? (
          <Card className="grid grid-cols-10">
            <Back to="/orders" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {order && order.id}
            </div>
            <Edit className="col-span-3 flex-row-reverse" />
            <h2 className="col-span-8 font-bold">
              {order && order.createdBy.name}
              <p className="col-span-full font-normal text-lh-text-black">
                {order && dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')}
              </p>
            </h2>
            <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
              <p>Estado</p>
              {order &&
                order.status &&
                (order.status === 'pending' ? (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                ) : order.status === 'accepted' ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0"
                  />
                ) : order.status === 'cancelled' ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0"
                  />
                ) : (
                  ''
                ))}
            </div>
            <hr className="col-span-full mt-2 mb-2" />
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Detalles del pedido
            </h2>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Producto: {order && order.product}
            </p>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Cantidad: {order && order.quantity}
            </p>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Enviar a: {order && order.shipTo}
            </p>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Fecha y hora de entrega: {order && order.dateTime}
            </p>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Cliente: {order && order.customer}
            </p>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              Codigo Obra: {order && order.shipToCode}
            </p>
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Comentario cliente:
            </h2>
            <p
              className={
                'col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black ' +
                (order && order.modifyText ? 'border-yellow-500' : '')
              }
            >
              {order && order.modifyText ? order.modifyText : 'Sin comentarios'}
            </p>
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Comentario Holcim:
            </h2>
            <p
              className={
                'col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black '
              }
            >
              {order && order.comment ? order.comment : 'Sin comentarios'}
            </p>

            {checkAccess(['sop', 'distributor']) && displayAcceptButton && (
              <Button
                onClick={() => setShowAccept(true)}
                color="green"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Aceptar
              </Button>
            )}
            {checkAccess(['sop', 'distributor']) && displayAcceptButton && (
              <Button
                onClick={() => setShowCancel(true)}
                color="red"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Cancelar
              </Button>
            )}
          </Card>
        ) : (
          <Card>Pedido no encontrado!</Card>
        )}
      </Content>
    </>
  );
};

export default Order;
