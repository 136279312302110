import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useOrder } from '../../../lib/hooks/use-orders';
import { useUser } from '../../../lib/hooks/use-user';

type EditOrderParams = 'id';

type EditOrderFormValues = {
  product: String;
  quantity: String;
  shipTo: String;
  dateTime: String;
};

const EditOrder = () => {
  const { id } = useParams<EditOrderParams>();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
    getValues,
    control,
  } = useForm<EditOrderFormValues>();
  const {
    order,
    error: orderError,
    update,
    loading: orderLoading,
  } = useOrder(id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showOrderAlert, setShowOrderAlert] = useState(false);
  const [showDistributorAlert, setShowDistributorAlert] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (orderError) {
      setShowOrderAlert(true);
    } else if (showOrderAlert) {
      setShowOrderAlert(false);
    }
    return () => {
      setShowOrderAlert(false);
    };
  }, [orderError]);

  useEffect(() => {
    if (order) {
      reset({
        product: order.product,
        quantity: order.quantity,
        shipTo: order.shipTo,
        dateTime: order.dateTime,
      });
    }
  }, [order, reset]);

  useEffect(() => {
    if (isSubmitSuccessful && !orderLoading) {
      navigate(`/orders/${id}`);
    }
  }, [isSubmitSuccessful, orderLoading]);

  const onSubmit: SubmitHandler<EditOrderFormValues> = async (data) => {
    console.log('Data', data);
    await update({
      product: data.product,
      quantity: data.quantity,
      shipTo: data.shipTo,
      dateTime: data.dateTime,
    });
  };

  return (
    <>
      <Alert
        message={orderError && orderError.message}
        open={showOrderAlert}
        setOpen={(open) => setShowOrderAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to save the changes?"
        open={showConfirmation}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => setShowConfirmation(false)}
        setOpen={(open) => setShowConfirmation(open)}
      />
      {order ? (
        order.status === 'pending' ? (
          <Content>
            <Card className="grid grid-cols-10">
              <Back to={`/orders/${id}`} className="col-span-3" />
              <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
                {order && order.id}
              </div>
              <h2 className="col-span-8 font-bold">
                {order && order.createdBy.name}
                <p className="col-span-full font-normal text-lh-text-black">
                  {order && dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')}
                </p>
              </h2>
              <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
                <p>Status</p>
                <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
              </div>
              <hr className="col-span-full mt-2 mb-2" />

              <form
                name="Edit Order"
                className="grid grid-cols-10 col-span-full"
              >
                <h2 className="font-bold col-span-10 mt-4 mb-2 text-lh-head-black">
                  Order Details
                </h2>
                <label
                  htmlFor="product"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Producto
                </label>
                <Input
                  register={register}
                  name="product"
                  type="text"
                  className="col-span-6 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="quantity"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Cantidad
                </label>
                <Input
                  register={register}
                  name="quantity"
                  type="text"
                  className="col-span-6 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="shipTo"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Enviar a
                </label>
                <Input
                  register={register}
                  name="shipTo"
                  type="text"
                  className="col-span-6 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="dateTime"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Fecha y hora de entrega
                </label>
                <Input
                  register={register}
                  name="dateTime"
                  type="text"
                  className="col-span-6 text-right text-lh-text-black mr-3"
                />
              </form>
              <Button
                text="Save changes"
                color="green"
                className="col-span-10"
                onClick={() => setShowConfirmation(true)}
              />
            </Card>
          </Content>
        ) : (
          <Navigate to={`/orders/${id}`} replace />
        )
      ) : (
        <Card>
          <p>Order not found!</p>
        </Card>
      )}
    </>
  );
};

export default EditOrder;
