import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
  PhoneAuthProvider,
} from 'firebase/auth';
import {
  DocumentSnapshot,
  getFirestore,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});
const firestore = getFirestore(app);
const functions = getFunctions(app, 'europe-west6');

/*
if (window.location.hostname === 'localhost') {
  console.log(
    'testing locally -- hitting local functions and firestore emulators'
  );
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
}
*/

// Firestore exports
const timestamp = serverTimestamp;
const fromMillis = Timestamp.fromMillis;

// Google Analytics
const analytics = getAnalytics(app);

// Firebase cloud messaging
const messaging = getMessaging(app);

// Enable offline persistance
enableIndexedDbPersistence(firestore).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

// Functions exports

const updatePhone = httpsCallable(functions, 'updatePhone');
const updateRetailerPhone = httpsCallable(functions, 'updateRetailerPhone');
const getScopedTypesenseKey = httpsCallable(functions, 'getScopedTypesenseKey');
const getScopedTypesenseKeyUserIds = httpsCallable(
  functions,
  'getScopedTypesenseKeyUserIds'
);

const googleAuthProvider = new GoogleAuthProvider();
const phoneAuthProvider = new PhoneAuthProvider(auth);

/**
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
function docToJSON<T>(doc: DocumentSnapshot | QueryDocumentSnapshot) {
  const data = doc.data();
  if (!data) return null;
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Timestamp) {
      data[key] = data[key].toMillis();
    }
  });
  return {
    id: doc.id,
    ...data,
  } as any as T;
}

export {
  auth,
  firestore,
  updatePhone,
  updateRetailerPhone,
  getScopedTypesenseKey,
  getScopedTypesenseKeyUserIds,
  timestamp,
  fromMillis,
  analytics,
  docToJSON,
  googleAuthProvider,
  phoneAuthProvider,
  messaging,
};
