import { createContext, useEffect, useState } from 'react';
import {
  analytics,
  auth,
  firestore,
  getScopedTypesenseKey,
  getScopedTypesenseKeyUserIds,
} from '../utils/firebase';
import { useAuthState } from '../auth/use-authState';
import { doc, getDoc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { User, AuthError, signOut } from 'firebase/auth';
import { logEvent, setUserProperties } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../../../global';
import { saveEndpoints, saveLastLogin } from '../../serviceWorkerRegistration';
interface ContextProps {
  id: string | null | undefined;
  user: User | null | undefined;
  roles: Role[] | null;
  typesenseKey: string | null | undefined;
  typesenseKeyUserIds: string | null | undefined;
  loading: boolean;
  error: AuthError | undefined;
}

export const AuthContext = createContext<ContextProps>({
  id: null,
  user: null,
  roles: null,
  typesenseKey: null,
  typesenseKeyUserIds: null,
  loading: true,
  error: undefined,
});

export const AuthProvider = ({ children }: any) => {
  const [user, loading, error] = useAuthState(auth);
  const [id, setId] = useState<string>();
  const [roles, setRoles] = useState<Role[]>([]);
  const navigate = useNavigate();
  const [savedEndpoints, setSavedEndpoints] = useState(false);
  const [typesenseKey, setTypesenseKey] = useState<string>();
  const [typesenseKeyUserIds, setTypesenseKeyUserIds] = useState<string>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    let timeout: NodeJS.Timeout;
    if (user) {
      const ref = doc(firestore, 'identities', user.uid);
      unsubscribe = onSnapshot(ref, (doc) => {
        if (doc.exists()) {
          if (timeout) {
            clearTimeout(timeout);
          }
          setRoles(doc.data()?.roles);
          setUserProperties(analytics, { roles: doc.data()?.roles });

          if (doc.data().id !== id) setId(doc.data()?.id);
          setUserProperties(analytics, { user_id: doc.data()?.id });
        } else {
          timeout = setTimeout(() => {
            signOut(auth);
            navigate('/denied', { replace: true });
          }, 10000);
        }
      });
    } else {
      setId(undefined);
      setRoles([]);
    }
    logEvent(analytics, 'user_login', { user_id: id, roles: roles.join(',') });
    return () => {
      if (unsubscribe) unsubscribe();
      if (timeout) clearTimeout(timeout);
    };
  }, [user]);

  useEffect(() => {
    if (user && id) {
      getScopedTypesenseKey(user.uid).then((result: any) => {
        setTypesenseKey(result.data?.typesenseKey);
      });
      getScopedTypesenseKeyUserIds(user.uid).then((result: any) => {
        setTypesenseKeyUserIds(result.data?.typesenseKey);
      });
    }
  }, [id, user]);

  useEffect(() => {
    let unsubscribe;
    if (user && !savedEndpoints) {
      let endpoints = null;
      const ref = doc(firestore, 'identities', user.uid);
      getDoc(ref).then((doc) => {
        endpoints = doc && doc.data() ? doc.data().endpoints : null;
        saveEndpoints(user.uid, endpoints);
      });
      setSavedEndpoints(true);
      saveLastLogin(user.uid);
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [savedEndpoints, user]);

  const value = {
    id,
    user,
    roles,
    typesenseKey,
    typesenseKeyUserIds,
    loading,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
