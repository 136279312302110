import { useOrders } from '../../lib/hooks/use-orders';
import Loader from '../../components/ui/Loader';
import React, { useEffect, useState } from 'react';
import useStore from '../../lib/hooks/use-store';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import Order from '../../components/orders/Order';
import List from '../../components/ui/list/List';
import Alert from '../../components/ui/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSync,
} from '@fortawesome/free-solid-svg-icons';

const Orders = React.memo(() => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFSA, setShowAlertFSA] = useState(false);

  const { error, orders, loading, load, hasMore, reset } = useOrders({
    status: state.status,
    direction,
    fsaId: state.selectedFSAOrders ? state.selectedFSAOrders.value : null,
  });

  const translations = {
    pending: 'pendiente',
    accepted: 'aceptado',
    cancelled: 'cancelado',
  };

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  useEffect(() => {
    if (
      state.status.includes('fulfilled') ||
      state.status.includes('rejected') ||
      state.status.includes('canceled')
    ) {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  }, [state.status]);

  const handleStatusChange = (newStatus) => () => {
    dispatch('SET_STATUS', newStatus);
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  return (
    <Content topBar>
      <ButtonBottomRight
        onClick={reset}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <Loader show={loading && orders.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('pending')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['pending'])}
        >
          Pendiente
        </div>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('accepted')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['accepted'])}
        >
          Aceptado
        </div>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('cancelled')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['cancelled'])}
        >
          Cancelado
        </div>
      </TopBar>
      {
        <List load={load} hasMore={hasMore} loading={loading}>
          {orders && orders.length > 0 ? (
            orders.map((order) => <Order order={order} key={order.id} />)
          ) : (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No{' '}
              {state.status
                .map((item) => {
                  return translations[item];
                })
                .join(' / ')}{' '}
              pedidos.
            </p>
          )}
        </List>
      }
    </Content>
  );
});

export default Orders;
