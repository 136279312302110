import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import configureStatusStore from './lib/store/status';
import configureRetailerStatusStore from './lib/store/retailerStatus';
import configureDateStore from './lib/store/date';
import configureRolesStore from './lib/store/roles';
import configureSelectedFSAOrdersStore from './lib/store/selectedFSAOrders';

configureStatusStore();
configureRetailerStatusStore();
configureDateStore();
configureRolesStore();
configureSelectedFSAOrdersStore();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
