import { Outlet, Product } from '../../../../global';

export const getTotalVolume = (products: Product): number => {
  let totalVolume = 0;
  if (!products) return 0;
  Object.keys(products).forEach((key) => {
    let conversionRate: number;
    if (key === 'classic' || key === 'supaset') {
      conversionRate = 0.05;
    } else {
      conversionRate = 0.02;
    }
    totalVolume += +products[key] * conversionRate;
  });
  return Math.round((totalVolume + Number.EPSILON) * 100) / 100;
};

export const sortByStatus = (a: Outlet, b: Outlet) => {
  const sa = a?.status;
  const sb = b?.status;
  if (sa === 'unconfirmed' && sb === 'confirmed') return -1;
  if (sa === 'unconfirmed' && sb === 'changes') return -1;
  if (sa === 'unconfirmed' && sb === 'changesRejected') return -1;
  if (sa === 'changes' && sb === 'confirmed') return -1;
  if (sa === 'changes' && sb === 'changesRejected') return -1;
  if (sa === 'changesRejected' && sb === 'confirmed') return -1;
  return 0;
};

export const some = <T>(arr1: T[], arr2: T[]): boolean => {
  return arr1.some((el) => arr2.includes(el));
};

export const unflatten = (data) => {
  if (Object(data) !== data || Array.isArray(data))
    return data;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while (m = regex.exec(p)) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
};
